import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function categoryUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'category_id', label: 'ID', sortable: true },
    // { key: 'parent_id', label: '父ID', sortable: true },
    // { key: 'level', label: '层级', sortable: true },
    // { key: 'path', label: '路径', sortable: true },
    { key: 'name', label: '名称', sortable: true },
    { key: 'erp_code', label: 'ERP编码', sortable: true },
    // { key: 'icon', label: 'ICON', sortable: true },
    // { key: 'image', label: '图片', sortable: true },
    // { key: 'banner', label: 'Banner', sortable: true },
    // { key: 'position', label: '位置', sortable: true },
    // { key: 'short_description', label: '简述', sortable: true },
    // { key: 'description', label: '描述', sortable: true },
    // { key: 'display_mode', label: '显示方式', sortable: true },
    // { key: 'landing_page', label: '落地页', sortable: true },
    // { key: 'attribute_set_id', label: '属性集ID', sortable: true },
    // { key: 'include_in_menu', label: '是否是菜单', sortable: true },
    // { key: 'children_count', label: '自分类数量', sortable: true },
    // { key: 'custom_design', label: '自定义样式', sortable: true },
    { key: 'state', label: '状态', sortable: true },
    { key: 'add_time', label: '添加时间', sortable: true },
    // { key: 'is_special', label: '是否专题', sortable: true },
    // { key: 'product_ids', label: '商品IDs', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(50)
  const lev = ref(1)
  const levOptions = [1, 2, 3]
  const limitOptions = [25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('category_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey, lev], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('category/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        level:lev.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '分类列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveCategoryTypeCode = value => {
    const val = String(value)
    if (val === '1') return {label: '渠道客户'}
    if (val === '2') return {label: '集团公司'}
    if (val === '3') return {label: '供应商'}
    if (val === '4') return {label: '仓库方'}
    if (val === '5') return {label: '物流方'}
    return {}
  }

  const resolveStateCode = value => {
    const val = String(value)
    if (val === '0') return {label: '禁用', color: 'secondary'}
    if (val === '1') return {label: '启用', color: 'success'}
    return {}
  }

  return {
    searchList,
    tableColumns,
    limit,
    lev,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    levOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    resolveCategoryTypeCode,
    resolveStateCode,
    refetchData,

    // Extra Filters
  }
}
