<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>
          <b-col
                  md="4"
                  cols="12"
          >
              <label>显示</label>
              <v-select
                      v-model="lev"
                      :options="levOptions"
                      :clearable="false"
                      class="per-menu-selector d-inline-block mx-50">
              </v-select>
              <label>级菜单</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(category_id)="data">
<!--            <b-link-->
<!--                    :to="{ name: 'apps-category-edit', query: { id: data.item.id } }"-->
<!--                    class="font-weight-bold d-block text-nowrap"-->
<!--            >-->
                #{{ data.item.category_id }}
<!--            </b-link>-->
        </template>
          <!-- Column: Type -->
          <template #cell(category_type)="data">
              {{resolveCategoryTypeCode(data.item.category_type).label}}
          </template>

     <template #cell(add_time)="data">
          {{toDate(data.item.add_time)}}
        </template>

        <!-- Column: State -->
     <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-${resolveStateCode(data.item.state).color}`"

          >
            {{resolveStateCode(data.item.state).label}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>


            <b-dropdown-item :to="{ name: 'apps-category-view', query: { id: data.item.id } }">
                  <feather-icon icon="FileTextIcon" />
                  <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.level <= 2"
                             v-b-modal.modal-category-add
                             @click="addItem(data.item)">
              <feather-icon icon="PlusIcon" />
              <span class="align-middle ml-50">增加下级</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <b-modal
              id="modal-category-add"
              ok-title="确认"
              cancel-title="取消"
              centered
              size="lg"
              @ok="checksubmit"
              title="下级商品"
      >
        <b-col md="5">
          <b-form-group
                  label="商品名称"
                  label-for="cost_reference"
                  class="mb-1"
          >
            <b-form-input
                    id="1"
                    size="sm"
                    v-model="name"
            />
          </b-form-group>
        </b-col>
       <b-col >
            <b-form-input
                    id="parent_id"
                    size="sm"
                    v-model="parent_id"
                    class="hidden"
            />
        </b-col>
        <b-col >
          <b-form-input
                    id="level"
                    size="sm"
                    v-model="level"
                    class="hidden"
            />
        </b-col>
      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText ,toDate,} from '@core/utils/filter'
import categoryUseList from './categoryUseList'
import categoryStore from './categoryStore'
import category from "../../../router/routes/apps/category";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    useToast,
  }, data() {
        return {
            parent_id: 0,
            level: 0,
            name:'',
        }
    },


  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('category/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  setup() {
    const toast = useToast();
    // Register module
    if (!store.hasModule('category')) store.registerModule('category', categoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('category')) store.unregisterModule('category')
    })

    const addItem = function (params) {
        this.name = "";
        this.parent_id = params.id;
        this.level = params.level;
    }

    const checksubmit = function () {
        store.dispatch('category/save', {parent_id: this.parent_id, level: this.level, name: this.name,}).then(res => {
          if (res.data.code==0){
            toast.success("保存成功!");
          }else {
            toast.error(res.data.data)
          }

      })
    }


    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      lev,
      levOptions,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      resolveCategoryTypeCode,
      resolveStateCode,
    } = categoryUseList()

    return {
      addItem,
      checksubmit,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      lev,
      levOptions,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      toDate,

      // UI
      resolveCategoryTypeCode,
      resolveStateCode,
    }
  },

}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.per-menu-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
